import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output,ViewChild, ElementRef} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import * as _ from "lodash";
import { TimelineComponent } from '../timeline/timeline.component';
import { Store } from '@ngxs/store';
import { RecordPage } from 'src/app/state/app.actions';
declare var $: any;

@Component({
  selector: 'field-many',
  templateUrl: './field-many.component.html',
  styleUrls: ['./field-many.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FieldManyComponent implements OnInit, OnChanges {

  @Input() public field;
  @Input() public record;
  @Input() public entity;
  @Input() public related;
  @Input() public user;
  @Input() public blockTitle;
  @Input() public isModal = false;
  @Input() public parentScroll = null;
  @Input() public isCreating: any;
  @Input() public params: any;

  @Output() public cardsFilter = new EventEmitter();
  @Output() public recordAddSubmit = new EventEmitter();
  @Output() public recordRemoveSubmit = new EventEmitter();
  @Output() public recordCreateSubmit = new EventEmitter();
  @Output() public cardClick = new EventEmitter();
  @Output() public loadMoreClick = new EventEmitter();
  @Output() public ganttDateChange = new EventEmitter();
  @Output() public ganttScopeChange = new EventEmitter();
  @Output() public ganttTaskClicked = new EventEmitter<any>();
  @Output() public ganttScroll = new EventEmitter();
  @Output() public modeChange = new EventEmitter();
  @ViewChild('create') create: ElementRef;
  @ViewChild('timeline', {static: false}) timeline: TimelineComponent;
  @ViewChild('offCanvas') offCanvas: ElementRef;
  isEmpty = _.isEmpty;
  jsonParse = JSON.parse;

  public readonly PAGE_SIZE = 10;
  public fetching = false;
  public ready = false;
  public page = 1;
  unscheduledPage = 1;


  constructor(private store: Store, private router: Router, private route: ActivatedRoute,) { }

  ngOnInit(): void {
  }
  ngOnChanges(changes: any): void {
    if (changes.related && this.related?.records) {
      if (!this.ready) {
        if (this.related.mode === 'cards') {
          this.ready = !this.related.loading && (this.related.records.length || this.related.count === 0);
        }
        if (this.related.mode === 'timeline') {
          this.ready = !this.related.loadingTimeline && (this.related.timelineRecords.length || this.related.timelineCount === 0);
          this.onUnscheduledNext();
        }
      }
    }
    if (this.fetching) {
      this.fetching = false;
    }
  }

  onUnscheduledNext() {
    this.store.dispatch(new RecordPage.RetrieveUnscheduledRecords(this.record.id, {}, this.unscheduledPage, this.field));
    this.unscheduledPage++;
  }

  onRecordDrag(data) {
    this.store.dispatch(new RecordPage.RecordDrag(this.record.id, {...data, field: this.field}));
  }

  onRecordCreateSubmit(evt){
    this.recordCreateSubmit.emit(evt);
  }

  onRecordAddSubmit(evt){
    this.recordAddSubmit.emit(evt);
  }

  onRecordRemoveSubmit(evt){
    this.recordRemoveSubmit.emit(evt);
  }

  onGanttTaskClicked(evt){
    this.ganttTaskClicked.emit(evt);
  }

  onGanttScroll(){
    this.page++;
    this.ganttScroll.emit({pageSize: this.PAGE_SIZE, page: this.page});
  }

  onLoadMoreClick(){
    this.page++;
    this.fetching = true;
    this.loadMoreClick.emit({pageSize: this.PAGE_SIZE, page: this.page});
  }

  onCardClick(evt){
    this.cardClick.emit(evt);
  }

  onGanttDateChange(evt) {
    this.ganttDateChange.emit(evt);
  }

  onGanttScopeChange(evt) {
    this.ganttScopeChange.emit(evt);
  }

  onChangeMode(mode) {
    let qp = {};
    if (mode !== this.related.mode){
      this.page = 1;
      this.ready = false;
      this.modeChange.emit(mode);
    }
    if (mode === 'cards') {
      qp['dashboardConfig'] = null;
      qp['timelineConfig'] = null;
      qp['calendarConfig'] = null;
      qp['timeframe'] = null;
      qp['mode'] = null;
    }
    this.router.navigate([], {
      queryParams: qp,
      queryParamsHandling: 'merge',
    });
  }

  getRemainingCount() {
    if (this.related.mode === 'cards') {
        return this.related.count - this.related.records.filter(record => record.id != -1).length;
    }
    if (this.related.mode === 'timeline') {
        return this.related.timelineCount - this.related.timelineRecords.filter(record => record.id != -1).length
    }
  }

  clearFilters() {
    const { ordering, groupby } = this.params;

    this.cardsFilter.emit({
      fieldId: this.field.id,
      filters: { ordering, groupby }
    })
  }

  onFilterUpdate(filters) {
    const { field: { name }, values } = filters;

    this.cardsFilter.emit({
      fieldId: this.field.id,
      filters: { ...this.params, [name]: values }
    });
  }

  onOrderingChange(ordering) {
    this.page = 1;
    this.cardsFilter.emit({
      fieldId: this.field.id,
      filters: { ...this.params, ordering }
    });
  }

  onGroupbyChange(groupby) {
    this.page = 1;
    this.cardsFilter.emit({
      fieldId: this.field.id,
      filters: { ...this.params, groupby }
    });
  }

  isOrderingDisabled(): boolean {
    return (
      (this.related.records.length === 0 && this.related.timelineRecords.length === 0 &&
        !(this.params['ordering'] || this.params['groupby'])) ||
      !(this.field.mirrorFields[0].entity.blocks .map((block: any) => block.fields).flat()
        .filter((field: any) => field.hasAscOrdering || field.hasDescOrdering).length
        )
    );
  }

  isGroupByDisabled(): boolean {
    return (
      (this.related.records.length === 0 && this.related.timelineRecords.length === 0 &&
        !(this.params['ordering'] || this.params['groupby'])) ||
      !(this.field.mirrorFields[0].entity.blocks.map((block: any) => block.fields).flat()
        .filter((field: any) => field.hasGroupby).length
        )
    );
  }

  openOffcanvas() {
    const offcanvasElement = this.offCanvas.nativeElement;
    const bodyElement = document.body;

    bodyElement.appendChild(offcanvasElement);
    $(offcanvasElement).offcanvas('show'); // Show the Offcanvas using
  }

}
